import "@babel/polyfill";
import $ from 'jquery';
import mainSlider from './__modules/mainSlider';
import tenants from './__modules/tenants';
import { CountUp } from "countup.js";
import { Fancybox, Carousel, Panzoom } from "@fancyapps/ui";
import Swiper, {Scrollbar, Keyboard, Mousewheel, Navigation, Autoplay, Pagination, EffectFade  } from 'swiper';

document.addEventListener('DOMContentLoaded', () => {
	// Check cookie value to hide cookie banner
	if(document.cookie.indexOf('cookie=') !== -1) {
		$(".cookie-banner").hide()
	}

	// Create variable --scrollbar-width for scrollbar width
	document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");

	// Menubar
	$(".menubar").on("click", function(e) {
		e.preventDefault();

		$(this).toggleClass("active");
		$(".header").toggleClass("header--mobile");
		$(".menu").slideToggle(400);
	});

	// Scripts related to the homepage
	if($(".main").length) {
		mainSlider();
	}

	// Filter date click and show filter items
	$(".filter-date").on("click", function(e) {
		e.preventDefault();

		$(this).toggleClass("active");
		$(".filter-items").slideToggle();
	});

	// Swiper init
	Swiper.use([Scrollbar, Keyboard, Mousewheel, Navigation, Autoplay, Pagination, EffectFade]);

	// Main Slider
	if($(".main").length) {
		if($(".main .swiper-slide").length == 1) {
			$('.main .swiper-wrapper').addClass( "disabled" );
			$('.main .swiper-pagination').hide();
		} else {
			new Swiper(".main .swiper-container", {
				direction: 'horizontal',
				effect: 'fade',
				loop: true,
				speed: 700,
				watchOverflow: true,
				keyboard: {
					enabled: true,
					onlyInViewport: false
				},
				pagination: {
					el: '.swiper-pagination',
					type: 'bullets',
					clickable: true
				},
				autoplay: {
					delay: 5000,
					disableOnInteraction: true
				},
				fadeEffect: {
					crossFade: true
				},
				slidesPerView: 1
			});
		}
	}

	// News section slider in homepage
	if($(".news-section").length) {
		new Swiper('.news-section .swiper-container', {
			slidesPerView: 1,
			spaceBetween: 30,
			loop: true,
			//mousewheel: true,
			keyboard: {
				enabled: true
			},
			breakpoints: {
				768: {
					centeredSlides: false,
					slidesPerView: 2
				},
				992: {
					centeredSlides: false,
					slidesPerView: 3
				},
				1200: {
					centeredSlides: false,
					slidesPerView: 4
				}
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			}
		});
	}

	// Tenants slider in homepage
	if($(".tenants-carousel").length) {
		let tenantsSwiper = new Swiper('.tenants-carousel .swiper-container', {
			slidesPerView: 1.225,
			centeredSlides: true,
			loop: false,
			grabCursor: true,
			resistance: true,
			watchSlidesProgress: true,
			slidesOffsetBefore: 0,
			speed: 600,
			keyboard: {
				enabled: true
			},
			breakpoints: {
				768: {
					slidesOffsetBefore: ($(window).width() - $(".swiper-scrollbar").width()) / 2,
					centeredSlides: false,
					slidesPerView: 2.1
				},
				992: {
					slidesOffsetBefore: ($(window).width() - $(".swiper-scrollbar").width()) / 2,
					centeredSlides: false,
					slidesPerView: 3.25
				},
				1200: {
					slidesOffsetBefore: ($(window).width() - $(".swiper-scrollbar").width()) / 2,
					centeredSlides: false,
					slidesPerView: 3.25
				},
				1600: {
					slidesOffsetBefore: ($(window).width() - $(".swiper-scrollbar").width()) / 2,
					centeredSlides: false,
					slidesPerView: 4.25
				}
			},
			scrollbar: {
				el: ".swiper-scrollbar",
				draggable: true,
				snapOnRelease: true
			},
			on: {
				resize: function() {
					if($(window).width() > 767) {
 						tenantsSwiper.params.slidesOffsetBefore = ($(window).width() - $(".swiper-scrollbar").width()) / 2;
						tenantsSwiper.update();
					}
				}
			}
		});
	}

	// Fill form from localstorage
	if($(".apply-form").length) {
		var formData = JSON.parse(localStorage.getItem("apply"));
		
		if(formData && formData.length) {
			restoreForm($(".apply-form"), formData);
		}
	}

	// Form pagination
	$(".apply-form-step a").on("click", function(e) {
		e.preventDefault();

		applyFormStepClick($(this));
	});

	// Form step input focus
	$(".form-step--control input, .form-step--control textarea").on("focus", function() {
		$(this).parent().addClass("form-step--control-active");
	});

	// Form step input on blur
	$(".form-step--control input, .form-step--control textarea").on("blur", function() {
		if(!$(this).val()) {
			$(this).parent().removeClass("form-step--control-active");
		}

		// Save form data
		localStorage.setItem("apply", JSON.stringify( $(".apply-form").serializeArray() ));
	});

	// Form step move
	$(".form-step--move a").on("click", function(e) {
		e.preventDefault();

		if($(this).hasClass("form-step--next")) {
			let itemIndex = $(".apply-form-step a.active").index() + 1;

			$(".apply-form-step a.active").removeClass("active");
			$(".apply-form-step a").eq(itemIndex).addClass("active");

			let translateVal = -20 * itemIndex;
			$(".form--step-wrapper ").css("transform", "translate(" + (translateVal + "%") + ", 0)");

			setTimeout(function() {
				$("html, body").animate({
					scrollTop: $(".apply-form-title").offset().top - $(".header").outerHeight() - 50
				}, 600, function() {
					$(".form--step").eq(itemIndex).find('input[type=text],textarea,select').filter(':visible:first').focus();
				});
			}, 400);
			
		} else {
			let itemIndex = $(".apply-form-step a.active").index() - 1;

			$(".apply-form-step a.active").removeClass("active");
			$(".apply-form-step a").eq(itemIndex).addClass("active");

			let translateVal = -20 * itemIndex;
			$(".form--step-wrapper ").css("transform", "translate(" + (translateVal + "%") + ", 0)");

			setTimeout(function() {
				$("html, body").animate({
					scrollTop: $(".apply-form-title").offset().top - $(".header").outerHeight() - 50
				}, 600, function() {
					$(".form--step").eq(itemIndex).find('input[type=text],textarea,select').filter(':visible:first').focus();
				});
			}, 400);
		}
	});

	// Scroll button click event
	$(".scroll").on("click", function(e) {
		e.preventDefault();

		$("html, body").animate({
			scrollTop: $(".article-main").height() - 100
		}, 800);
	});

	// Shape wrappers same height
	if($(".shape-wrapper").length && $(window).width() > 767) {
		getShapeItemHeight();
	}

	// Accordion item click
	$(".accordion-header").on("click", function(e) {
		e.preventDefault();

		$(this).toggleClass("accordion-header--active");
		$(this).next().slideToggle();
	});

	// Service Text - check if to open the link or to move to the section
	$(".service-text a").on("click", function(e) {
		var hrefLink = $(this).attr("href");

		if (hrefLink.indexOf("http://") != 0 && hrefLink.indexOf("https://") != 0) {
			e.preventDefault();
			moveToSection(hrefLink);
		}
	});

	// Tenants Search
	if($("#tenant-search").length) {
		tenants();
	}

	// Form step next trigger click on step tab
	$(".form-step--next").on("keydown", function(e) {
		if(e.keyCode == 9) {
			e.preventDefault();

			$(this).trigger("click");
		}
	});

	// Form step checkbox
	$(".form-step--checkbox").on("click", function(e) {
		e.preventDefault();

		let checkBox = $(this).find(".checkbox");
		checkBox.toggleClass("selected");

		if(checkBox.hasClass("selected")) {
			$(".form-control--submit .btn").removeAttr("disabled")
		} else {
			$(".form-control--submit .btn").attr("disabled", true)
		}
	})

	// Cookie banner checkbox
	$(".cookie-section--checkbox").on("click", function(e) {
		e.preventDefault();

		if(!$(this).hasClass("required")) {
			$(this).toggleClass("selected")
		}
	})

	// Cookie banner more information
	$(".cookie-section--more").on("click", function(e) {
		e.preventDefault();

		$(this).toggleClass("active").parent().next().slideToggle();
	})

	// Cookie selection
	$(".cookie-buttons a").on("click", function(e) {
		e.preventDefault();

		document.cookie = "cookie=1";
		$(".cookie-banner").hide()
	});

	// Tab Item
	$(".tab-item").on("click", function(e) {
		e.preventDefault();

		let clickedItem = $(this);

		if(!clickedItem.hasClass("active")) {
			$(".tab-item.active").removeClass("active");
			clickedItem.addClass("active");

			let itemIndex = clickedItem.index(),
				activeContentItem = $(".tab-content-item.active");

			activeContentItem.slideUp(400, function() {
				activeContentItem.removeClass("active");
				$(".tab-content-item").eq(itemIndex).addClass("active").slideDown(400);
			});
		}
	});

	$(".dtc-tab-item").on("click", function(e) {
		let clickedItem = $(this),
			itemHref = clickedItem.attr("href");

		if(clickedItem.closest(".dtc-tab-item-container").length > 0) {
			clickedItem.next().slideDown();
			//clickedItem.next().find("active").removeClass("active");
		} else {
			$(".dtc-tab-item-container ul").slideUp();	
			$(".dtc-tab-item-container .active").removeClass("active");
		}

		if(!clickedItem.hasClass("active")) {
			if(itemHref.charAt(0) === "#") {
				e.preventDefault();

				$(".dtc-tab-item.active").removeClass("active");
				clickedItem.addClass("active");

				let activeContentItem = $(".dtc-tab-content-item.active");
				activeContentItem.slideUp(400, function() {
					activeContentItem.removeClass("active");
					$(itemHref).addClass("active").slideDown(400);
				});
			}
		}
	});

	$(".dtc-tab-item-container ul li a").on("click", function(e) {
		e.preventDefault();

		let clickedItem = $(this),
			itemHref = clickedItem.attr("href");

		if(!clickedItem.hasClass("active")) {
			if(itemHref.charAt(0) === "#") {
				e.preventDefault();

				$(".dtc-tab-item-container .active").removeClass("active");
				clickedItem.addClass("active");

				let activeContentItem = $(".dtc-tab-content-item.active");
				activeContentItem.slideUp(400, function() {
					activeContentItem.removeClass("active");
					$(itemHref).addClass("active").slideDown(400);
				});
			}
		}
	});

	// Count up effect
	if($(".info-box").length) {
		const infoBox1 = new CountUp('info-box-1', $("#info-box-1").attr("data-nr"), {
				enableScrollSpy: true,
				scrollSpyDelay: 1000,
				scrollSpyOnce: false,
				useGrouping: false,
				duration: 3,
			}), infoBox2 = new CountUp('info-box-2', $("#info-box-2").attr("data-nr"), {
				enableScrollSpy: true,
				scrollSpyDelay: 1000,
				scrollSpyOnce: false,
				useGrouping: false,
				duration: 3
			}), infoBox3 = new CountUp('info-box-3', $("#info-box-3").attr("data-nr"), {
				enableScrollSpy: true,
				scrollSpyDelay: 1000,
				scrollSpyOnce: false,
				useGrouping: false,
				duration: 3
			}), infoBox4 = new CountUp('info-box-4', $("#info-box-4").attr("data-nr"), {
				enableScrollSpy: true,
				scrollSpyDelay: 1000,
				scrollSpyOnce: false,
				useGrouping: false,
				duration: 3
			}), infoBox5 = new CountUp('info-box-5', $("#info-box-5").attr("data-nr"), {
				enableScrollSpy: true,
				scrollSpyDelay: 1000,
				scrollSpyOnce: false,
				useGrouping: false,
				duration: 3
			}), infoBox6 = new CountUp('info-box-6', $("#info-box-6").attr("data-nr"), {
				enableScrollSpy: true,
				scrollSpyDelay: 1000,
				scrollSpyOnce: false,
				useGrouping: false,
				duration: 3
			});
	}

	// Footer Menu Click
	$(".footer-menu--inner li a").on("click", function(e) {
		var itemHref = $(this).attr("href");

		if(itemHref.charAt(0) === "#") {
			e.preventDefault();

			if(!$(this).parent("li").hasClass("active")) {
				$(".footer-menu--inner li.active").removeClass("active");
				$(this).parent("li").addClass("active");
			}

			$("html, body").animate({
				scrollTop: $(itemHref).offset().top - 120
			}, 800);
		}
	});

	// Show footer menu close button on mobile instead of the menu
	if($(window).width() < 992 && $(".footer-menu--inner").length) {
		$(".footer-menu--inner").addClass("hide");
		$(".footer-menu--open").addClass("show");
	}
	
	// Footer Menu Close
	$(".footer-menu--close").on("click", function(e) {
		e.preventDefault();

		$(".footer-menu--inner").addClass("hide");
		$(".footer-menu--open").addClass("show");
	});

	// Footer Menu Open
	$(".footer-menu--open").on("click", function(e) {
		e.preventDefault();

		$(".footer-menu--inner").removeClass("hide");
		$(".footer-menu--open").removeClass("show");
	});

	// Digital Business Menu
	$(".digital-business--menu li a").on("click", function(e) {
		var itemHref = $(this).attr("href");

		if(itemHref.charAt(0) === "#") {
			e.preventDefault();

			if(!$(this).parent("li").hasClass("active")) {
				$(".footer-menu--inner li.active").removeClass("active");
				$(this).parent("li").addClass("active");
			}

			$("html, body").animate({
				scrollTop: $(itemHref).offset().top - 120
			}, 800);
		}
	});

	// DB Menubar click
	$(".db-menubar").on("click", function() {
		$(this).toggleClass("active");
		$(".db-menu").toggleClass("show");
	});

	// DB Menu
	$(".db-menu--items li a").on("click", function(e) {
		var itemHref = $(this).attr("href");

		if(itemHref.charAt(0) === "#") {
			e.preventDefault();

			if(!$(this).parent("li").hasClass("active")) {
				$(".db-menu--items li.active").removeClass("active");
				$(this).parent("li").addClass("active");
			}

			if($(window).width() < 992) {
				$(".db-menubar").trigger("click");
			}

			$("html, body").animate({
				scrollTop: $(itemHref).offset().top - 90
			}, 800);
		}
	});

	// Action Part
	$(".action-part").on("click", function(e) {
		e.preventDefault();

		$("html, body").animate({
			scrollTop: $("#action-part").offset().top - 100
		}, 800);
	});

	// Icon information click on services
	$(".information").on("click", function(e) {
		e.preventDefault();

		$(".service-section--info").slideToggle();
	});

	// Service section info close
	$(".service-section--info .icon-close").on("click", function(e) {
		e.preventDefault();
		$(".service-section--info").slideToggle();
	});

	// Pillars box click
	// $(".pillars-box").on("click", function(e) {
	// 	e.preventDefault();

	// 	let clickedItem = $(this),
	// 		itemIndex = $(this).index();

	// 	$(".dtc-tab-item-pillar ul li:eq(" + itemIndex + ") a").trigger("click");
	// });

	// Activity Box
	$(".activity-box > a, .activity-box > h3 a").on("click", function(e) {
		console.log("Clicked");
		var itemHref = $(this).attr("href");

		$(".dtc-tab-item-container ul li a[href='" + itemHref + "']").trigger("click");

		$(".dtc-tab-item.active").removeClass("active");
		$(".dtc-tab-item-container .dtc-tab-item").addClass("active");
		$(".dtc-tab-item-container ul").slideDown();

		$("html, body").animate({
		 	scrollTop: $(".dtc-wrapper").offset().top - 150
		}, 800);
	});
});

window.addEventListener('load', () => {
	if($(window).scrollTop() > 0) {
		$(".header").addClass("header--fixed");
	} else {
		$(".header").removeClass("header--fixed");
	}

	if(jQuery(".filter-form").length) {
        jQuery("#filter-form--search-sq").on("click", function(e) {
            e.preventDefault();
            e.stopPropagation();
            const trainingBoxes = document.querySelector('.training-boxes');
            trainingBoxes.innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgb(255, 255, 255); display: block; shape-rendering: auto; text-align: center;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <circle cx="50" cy="50" fill="none" stroke="#ff6600" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                </circle>
            </svg>`;
            
            let formObject = $('.filter-form').serializeArray();

            jQuery.ajax({
                type: "POST",
                dataType: "html",
                //url: specialObj.ajaxurl,
				url: $(".top-space").attr("data-call"),
                data: {
                    action: "filter_training",
                    formFields: formObject,
                    //nonce: specialObj.security,
                },
                success: (data) => {
                    const trainingBoxes = document.querySelector('.training-boxes');
                    trainingBoxes.innerHTML = data;
                },
                error: () => {}
            });

        })

        jQuery("#filter-form--clear").on("click", function(e) {
            e.preventDefault();
            e.stopPropagation();

            const form = [...$('.filter-form input, .filter-form select')];

            form.forEach(element => {
               element.value = '';
            });
        })
    }
});

window.addEventListener('resize', () => {

	if($(window).width() > 767) {
		getShapeItemHeight();
	}

	if($(window).width() > 991) { /** CHECK */
		// Menu on resize remove style
		$(".menu").removeAttr("style");
	}

});

window.addEventListener('scroll', () => {
	let windowScroll = $(window).scrollTop();

	// Add class to header on scroll
	if(windowScroll > 0) {
		$(".header").addClass("header--fixed");
	} else {
		$(".header").removeClass("header--fixed");
	}
	
	// Service text for DTC make progress
	if($(".service-text").length && $(window).width() > 991) {
		$(".service-text .section").each(function(index, element) {
			let sectionOffset = $(element).offset().top,
				sectionHeight = $(element).height() - 45,
				scrolledHeight = windowScroll - sectionOffset + 112,
				percentageScrolled = scrolledHeight / sectionHeight * 100;

			if(percentageScrolled > 0 && percentageScrolled < 101) {
				$(element).find(".service-text-progress").css("width", Math.floor(percentageScrolled) + "%");
			} else if(percentageScrolled < 0) {
				$(element).find(".service-text-progress").css("width", "0%");
			}
		});
	}

	// Footer menu change active on scroll
	if($(".footer-menu--inner").length) {
		var footerMenuItems = [],
			scrollTop = $(window).scrollTop() + 150;

		$(".footer-menu--inner li").each(function(item, element) {
			var itemID = $(element).find("a").attr("href").split("#")[1];

			if($("#" + itemID).length != 0) {
				footerMenuItems.push(itemID);
			}
		});

		var cur = footerMenuItems.filter(function(item) {
			if($("#"+item).offset().top < scrollTop) return item;
		})

		if(cur) {
			console.log("a[href='#" + cur[cur.length-1] +"']");
			$(".footer-menu--inner").find("li.active").removeClass("active");
			$(".footer-menu--inner a[href='#" + cur[cur.length-1] +"']").parent().addClass("active");
		}
	}

	// Header digital
	if($(".header-digital").length) {

		if(windowScroll > 0) {
			$(".header-digital").addClass("header-digital--sticky");
		} else {
			$(".header-digital").removeClass("header-digital--sticky");
		}
	}

	// Header Business template
	if($(".db-header").length) {

		var dbMenuItems = [],
			scrollTop = $(window).scrollTop() + 100;

		$(".db-menu--items li").each(function(item, element) {
			var itemID = $(element).find("a").attr("href").split("#")[1];

			if(itemID && $("#" + itemID).length != 0) {
				dbMenuItems.push(itemID);
			}
		});

		var cur = dbMenuItems.filter(function(item) {
			if($("#"+item).offset().top < scrollTop) return item;
		})

		if(cur) {
			console.log("a[href='#" + cur[cur.length-1] +"']");
			$(".db-menu--items").find("li.active").removeClass("active");
			$(".db-menu--items a[href='#" + cur[cur.length-1] +"']").parent().addClass("active");
		}

		if(windowScroll > 0) {
			$(".db-header").addClass("db-header--fixed");
		} else {
			$(".db-header").removeClass("db-header--fixed");
		}
	}
});

function getShapeItemHeight() {
	let shapeWrapperHeight = 0;
	let shapeTextHeight = 0;

	$(".sector-shapes li").each(function(index, item) {
		let shapeItem = $(item),
			shapeWrapperItem = shapeItem.find(".shape-wrapper"),
			shapeItemHeight = shapeWrapperItem.height(),
			shapeItemTextHeight = shapeItem.find(".shape-text").height();

		if(shapeItemHeight > shapeWrapperHeight) {
			shapeWrapperHeight = shapeItemHeight;
		}

		if(shapeItemTextHeight > shapeTextHeight) {
			shapeTextHeight = shapeItemTextHeight;
		}

		$(".shape-wrapper").css("height", shapeWrapperHeight + "px");
		$(".shape-text").css("height", shapeTextHeight + "px");
	});
}

function moveToSection(sectionId) {
	$("html, body").animate({
		scrollTop: $(sectionId).offset().top - $(".header").outerHeight() - 45
	}, 800);
}

function restoreForm(form, formArray) {
	formArray.forEach(function (pair) {
		var selector = `input[name="${ pair.name }"], textarea[name="${ pair.name }"]`,
			input = $(form).find(selector);

		input.val(pair.value);

		if(pair.value) {
			$(selector).parent().addClass("form-step--control-active");
		}
	});
}

// Apply form step click
function applyFormStepClick(item) {
	let itemIndex = item.index();

	if(!item.hasClass("active")) {
		$(".apply-form-step a.active").removeClass("active");
		item.addClass("active");

		let translateVal = -20 * itemIndex;

		$(".form--step-wrapper ").css("transform", "translate(" + (translateVal + "%") + ", 0)");
	}
}
