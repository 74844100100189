import $ from 'jquery';

const tenants = () => {
	// Get tenants from search
	$("#tenant-search").on("submit", function(e) {
		e.preventDefault();

		let tenantSearchInputValue = $("#tenant-search input[type='text']").val();
		
		if(tenantSearchInputValue !== '') {
			$(".tenant").fadeOut(400, function() {
				$(".tenant").remove();
			});

			if($(".pagination").length) {
				$(".pagination").fadeOut(400, function() {
					$(".pagination").remove();
				});
			}

			$(".tenants").append("<div class='loading'><h3>Loading...</h3></div>");

			$.ajax({
				type: "post",
				url: specialObj.ajaxurl,
				data: {
					action: "get_tenants",
					nonce: specialObj.security,
					tenant: tenantSearchInputValue
				},
				success: function(response) {
					$(".loading").fadeOut(400, function() {
						$(".loading").remove();
						$(".tenants").append(response);
					});
				}
			});
		} else {
			$("#tenant-search").addClass("tenant-search--error");
		}
	});

	$("#tenant-search input[type='text']").on("keydown", function() {
		if($("#tenant-search").hasClass("tenant-search--error")) {
			$("#tenant-search").removeClass("tenant-search--error");
		}
	});

};

export default tenants;
